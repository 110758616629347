/**
 * Copyright 2021 TM9657 GmbH. All Rights Reserved.
 *
 * Landing, Home Page. Keep this clean and lean
 * use components where possible
 */

import {useEffect} from "react";
import {useRouter} from "next/router";

//reimport
export default function Home(props) {

    const router = useRouter();

    useEffect(() => {
        router.push("/")
    }, [])

    return (
        <>
        </>
    );
}
